import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import AccentLine from '../../components/AccentLine';
import ListingsListFeed from '../../components/listings/ListingsListFeed';

export default function FeedPage({ params }) {
    return <Layout>
        <SEO
            title="Featured Listings With Parker Coulter Realty"
            description="Search Our MLS® Real Estaste Listings — Get In Touch With Our Realtors For Apartments, Houses, Estates & Condos. MLS® listings & Property Information To Make Finding Your Dream Home Easy!"
        />
        <StyledArticle>
            <section className="intro-section">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column has-text-centered">
                            <h1>Real Estate Listings</h1>
                            <AccentLine align="center" />
                            <p className="sub-head">Explore Our Current Listings</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section listings-section">
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <ListingsListFeed />
                        </div>
                    </div>
                </div>
            </section>
        </StyledArticle>
    </Layout>;
}

const StyledArticle = styled.article`
	padding-top: 15rem;
	padding-bottom: 7.5rem;

	@media (max-width: 768px) {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	.intro-section {
		margin-bottom: 8rem;
		padding: 0 1.5rem;

		@media (max-width: 768px) {
			margin-bottom: 3rem;
		}
	}
`;





